import { Vue } from 'vue-class-component'
import { Tournaments } from '@/services/TournamentsDataService'
import SeasonsDataService from '@/services/SeasonsDataService'
import SysTournamentRow, { SysTournamentCategoryId, SysTournamentMatchSchedule, SysTournamentRowId, SysTournamentMatchSettlement, SysTournamentMatch, SysTournamentMatchSet, SysTournamentMatch180, tournamentMatchScheduleTeamStatusFriendlyType } from '@/types/SysTournament'
import SysSaeson, { SysSaesonTournamentRowId } from '@/types/SysSaeson'
import CommonFunctions, { defaultMainTournamentId, defaultDailyTournamentId, defaultOldBoys35Id, defaultOldBoys35WestId, defaultOldBoys50Id, defaultJuniorTournamentSpringId, defaultJuniorTournamentFallId, defaultWomenPairsId, defaultParadartTournamentId } from '@/components/Utility/Common'
import BaseFunctions from '@/components/Utility/Base'

type dataReturnType = { series: any; }

export default class tournaments extends Vue {
  private currentSeason = {} as SysSaeson
  private currentSeasonId = 0
  private series: SysTournamentRow[] = []
  private mainTournament: SysSaesonTournamentRowId[] = []
  private dailyTournament: SysSaesonTournamentRowId[] = []
  private old35: SysSaesonTournamentRowId[] = []
  private old50: SysSaesonTournamentRowId[] = []
  private junior: SysSaesonTournamentRowId[] = []
  private women: SysSaesonTournamentRowId[] = []
  private paradart: SysSaesonTournamentRowId[] = []
  private locDefaultJuniorTournamentSpringId = 0
  private locDefaultJuniorTournamentFallId = 0
  private finalDateOfSeasonToShowMarginOfDays = 14

  data (): dataReturnType {
    return {
      series: this.series
    }
  }

  public async retrieveCurrentSeason () : Promise<void> {
    let finalDateOfSeasonToShow = new Date()
    try {
      const datePromise = await BaseFunctions.getDatetimeFromServer()
      finalDateOfSeasonToShow = new Date(datePromise.data)
    } catch (err) {
      console.log(err)
      finalDateOfSeasonToShow = new Date()
    }
    const finalDateOfSeasonToShowWithoutMargin = new Date(finalDateOfSeasonToShow)
    finalDateOfSeasonToShow.setDate(finalDateOfSeasonToShowWithoutMargin.getDate() - this.finalDateOfSeasonToShowMarginOfDays)
    const finalDateOfSeasonToShowText = finalDateOfSeasonToShow.toISOString().split('T')[0]

    SeasonsDataService.getAll('', null, `saeson_status=true&saeson_slut_gte=${finalDateOfSeasonToShowText}`)
      .then((response) => {
        console.log(response.data)
        const season = response.data[0] as SysSaeson
        this.currentSeason = season
        this.currentSeasonId = Number(season.id)
        this.mainTournament = this.currentSeason.raekke_id.filter(el => el.turneringskategori_id === defaultMainTournamentId && el.raekke_status === true).sort((a, b) => a.raekke_navn !== b.raekke_navn ? a.raekke_navn < b.raekke_navn ? -1 : 1 : 0)
        this.dailyTournament = this.currentSeason.raekke_id.filter(el => el.turneringskategori_id === defaultDailyTournamentId && el.raekke_status === true).sort((a, b) => a.raekke_navn !== b.raekke_navn ? a.raekke_navn < b.raekke_navn ? -1 : 1 : 0)
        this.old35 = this.currentSeason.raekke_id.filter(el => (el.turneringskategori_id === defaultOldBoys35Id || el.turneringskategori_id === defaultOldBoys35WestId) && el.raekke_status === true).sort((a, b) => a.raekke_navn !== b.raekke_navn ? a.raekke_navn < b.raekke_navn ? -1 : 1 : 0)
        this.old50 = this.currentSeason.raekke_id.filter(el => el.turneringskategori_id === defaultOldBoys50Id && el.raekke_status === true).sort((a, b) => a.raekke_navn !== b.raekke_navn ? a.raekke_navn < b.raekke_navn ? -1 : 1 : 0)
        this.women = this.currentSeason.raekke_id.filter(el => el.turneringskategori_id === defaultWomenPairsId && el.raekke_status === true).sort((a, b) => a.raekke_navn !== b.raekke_navn ? a.raekke_navn < b.raekke_navn ? -1 : 1 : 0)
        this.junior = this.currentSeason.raekke_id.filter(el => (el.turneringskategori_id === defaultJuniorTournamentSpringId || el.turneringskategori_id === defaultJuniorTournamentFallId) && el.raekke_status === true).sort((a, b) => a.raekke_navn !== b.raekke_navn ? a.raekke_navn < b.raekke_navn ? -1 : 1 : 0)
        this.paradart = this.currentSeason.raekke_id.filter(el => el.turneringskategori_id === defaultParadartTournamentId && el.raekke_status === true).sort((a, b) => a.raekke_navn !== b.raekke_navn ? a.raekke_navn < b.raekke_navn ? -1 : 1 : 0)
        console.log('[retrieveCurrentSeason()] junior =' + JSON.stringify(this.junior))
      })
      .catch((err) => {
        console.log(err)
      })

    console.log(finalDateOfSeasonToShowText)
    // SeasonsDataService.getAll('created_at:desc', { slicemode: 0, start: 0, limit: -1, page: 0, pagesize: 0, totalcount: 0 }, 'saeson_status=true')
    //   .then((response) => {
    //     console.log(response.data)
    //     this.currentSeason = response.data[0]
    //     this.currentSeasonId = response.data[0].id
    //     console.log(this.currentSeason)
    //     this.mainTournament = this.currentSeason.raekke_id.filter(el => el.turneringskategori_id === defaultMainTournamentId).sort((a, b) => a.raekke_navn !== b.raekke_navn ? a.raekke_navn < b.raekke_navn ? -1 : 1 : 0)
    //     this.dailyTournament = this.currentSeason.raekke_id.filter(el => el.turneringskategori_id === defaultDailyTournamentId).sort((a, b) => a.raekke_navn !== b.raekke_navn ? a.raekke_navn < b.raekke_navn ? -1 : 1 : 0)
    //     this.old35 = this.currentSeason.raekke_id.filter(el => el.turneringskategori_id === defaultOldBoys35Id).sort((a, b) => a.raekke_navn !== b.raekke_navn ? a.raekke_navn < b.raekke_navn ? -1 : 1 : 0)
    //     this.old50 = this.currentSeason.raekke_id.filter(el => el.turneringskategori_id === defaultOldBoys50Id).sort((a, b) => a.raekke_navn !== b.raekke_navn ? a.raekke_navn < b.raekke_navn ? -1 : 1 : 0)
    //     this.women = this.currentSeason.raekke_id.filter(el => el.turneringskategori_id === defaultWomenPairsId).sort((a, b) => a.raekke_navn !== b.raekke_navn ? a.raekke_navn < b.raekke_navn ? -1 : 1 : 0)
    //     this.junior = this.currentSeason.raekke_id.filter(el => el.turneringskategori_id === defaultJuniorTournamentId).sort((a, b) => a.raekke_navn !== b.raekke_navn ? a.raekke_navn < b.raekke_navn ? -1 : 1 : 0)
    //   })
    //   .catch((err) => {
    //     console.error(err)
    //   })
  }

  public generateTournamentSlug (item : string, seasonId: number, seriesId: number) : string {
    const slug = seasonId + '-' + seriesId + '_' + CommonFunctions.slugify(item)
    return slug
  }

  async mounted () {
    this.locDefaultJuniorTournamentSpringId = defaultJuniorTournamentSpringId
    this.locDefaultJuniorTournamentFallId = defaultJuniorTournamentFallId
    this.retrieveCurrentSeason()
  }
}
