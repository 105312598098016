
import { Options, Vue } from 'vue-class-component'
import AdminTournaments from '@/components/Tournaments/AdminTournaments/index.vue' // @ is an alias to /src

@Options({
  components: {
    AdminTournaments
  }
})
export default class TournamentsView extends Vue {}
