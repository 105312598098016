
import { Options, Vue } from 'vue-class-component'
import tournaments from '@/components/Frontend/Tournaments/index.vue'

@Options({
  components: {
    tournaments
  }
})

export default class Tournaments extends Vue {}
