import { Tournaments } from '@/services/TournamentsDataService'
import SysTeam from '@/types/SysTeam'
import { SysTournamentMatchSchedule, SysTournamentMatchSettlement, SysTournamentRules, tournamentMatchScheduleTeamStatusFriendlyType } from '@/types/SysTournament'

export type teamRankingsArrayType = { teamId: number; teamName: string; points: number; matches: number; won: number; lost: number; tied: number; wonScore: number; lostScore: number; matchNumbers: number[] }

class TournamentCommonFunctions {
  public async buildMatchesTeamRankings (seriesString: string, rules: SysTournamentRules[], teams: SysTeam[], matchSchedule: SysTournamentMatchSchedule[]) : Promise<teamRankingsArrayType[]> {
    const teamRankingsArray: teamRankingsArrayType[] = []
    let matchScores: SysTournamentMatchSettlement[] = []
    // let rules = response[0].data as SysTournamentRules[]
    let isFinishingMatchSeries = false
    // const teams = response[1].data as SysTeam[]
    // const matchSchedule = response[2].data as SysTournamentMatchSchedule[]
    console.log('[getRanking()] series = ' + seriesString.toLowerCase())

    if (seriesString.toLowerCase().includes('slutspil')) {
      isFinishingMatchSeries = true
    }

    // Filter the rules to find the correct ones
    if (seriesString.toLowerCase().includes('damepairs')) {
      console.log('[getRanking()] Damepairs rules filtering is used.')
      rules = rules.filter(el => el.turneringskategori_id.turneringskategori_navn.toLowerCase().includes('damepairs'))
    } else if (seriesString.toLowerCase().includes('junior')) {
      console.log('[getRanking()] Junior rules filtering is used.')
      rules = rules.filter(el => el.turneringskategori_id.turneringskategori_navn.toLowerCase().includes('juniorrækken'))
    } else if (seriesString.toLowerCase().includes('oldboys 35') || seriesString.toLowerCase().includes('oldboys +35')) {
      console.log('[getRanking()] Oldboys 35+ rules filtering is used.')
      rules = rules.filter(el => el.turneringskategori_id.turneringskategori_navn.toLowerCase().includes('oldboys 35'))
    } else if (seriesString.toLowerCase().includes('oldboys 50') || seriesString.toLowerCase().includes('oldboys +50')) {
      console.log('[getRanking()] Oldboys 50+ rules filtering is used.')
      rules = rules.filter(el => el.turneringskategori_id.turneringskategori_navn.toLowerCase().includes('oldboys 50'))
      // } else if (seriesString.toLowerCase().includes('hverdag region') && (parseInt(seriesString) === 3 || parseInt(seriesString) === 4 || parseInt(seriesString) === 5)) {
    } else if (seriesString.toLowerCase().includes('hverdag region')) {
      const posInString = seriesString.toLowerCase().trim().search('hverdag region')
      const endPartOfString = seriesString.toLowerCase().trim().substring(posInString + 14).trim()

      if (endPartOfString.startsWith('1')) {
        console.log('[getRanking()] Hverdagsrække 1 rules filtering is used.')
        rules = rules.filter(el => el.turneringskategori_id.turneringskategori_navn.toLowerCase().includes('hverdagsrækken') && (el.regions.findIndex(x => x.region_navn.toLowerCase().trim().startsWith('region 1')) >= 0))
      } else if (endPartOfString.startsWith('2')) {
        console.log('[getRanking()] Hverdagsrække 2 rules filtering is used.')
        rules = rules.filter(el => el.turneringskategori_id.turneringskategori_navn.toLowerCase().includes('hverdagsrækken') && (el.regions.findIndex(x => x.region_navn.toLowerCase().trim().startsWith('region 2')) >= 0))
      } else if (endPartOfString.startsWith('3')) {
        console.log('[getRanking()] Hverdagsrække 3 rules filtering is used.')
        rules = rules.filter(el => el.turneringskategori_id.turneringskategori_navn.toLowerCase().includes('hverdagsrækken') && (el.regions.findIndex(x => x.region_navn.toLowerCase().trim().startsWith('region 3')) >= 0))
      } else if (endPartOfString.startsWith('4')) {
        console.log('[getRanking()] Hverdagsrække 4 rules filtering is used.')
        rules = rules.filter(el => el.turneringskategori_id.turneringskategori_navn.toLowerCase().includes('hverdagsrækken') && (el.regions.findIndex(x => x.region_navn.toLowerCase().trim().startsWith('region 4')) >= 0))
      } else if (endPartOfString.startsWith('5')) {
        console.log('[getRanking()] Hverdagsrække 5 rules filtering is used.')
        rules = rules.filter(el => el.turneringskategori_id.turneringskategori_navn.toLowerCase().includes('hverdagsrækken') && (el.regions.findIndex(x => x.region_navn.toLowerCase().trim().startsWith('region 5')) >= 0))
      } else {
        console.log('[getRanking()] Hovedturnering rules filtering is used (default).')
        rules = rules.filter(el => el.turneringskategori_id.turneringskategori_navn.toLowerCase().includes('hovedturnering'))
      }
    } else {
      console.log('[getRanking()] Hovedturnering rules filtering is used (default).')
      rules = rules.filter(el => el.turneringskategori_id.turneringskategori_navn.toLowerCase().includes('hovedturnering'))
    }
    // console.log('[getRanking()] rules = ' + JSON.stringify(rules))

    // Initialize the ranking array
    for (const team of teams) {
      teamRankingsArray.push({
        teamId: Number(team.id),
        teamName: team.hold_holdnavn,
        points: (isFinishingMatchSeries && team.hold_startpoint !== undefined && team.hold_startpoint != null ? Number(team.hold_startpoint) : 0),
        matches: 0,
        won: 0,
        lost: 0,
        tied: 0,
        wonScore: 0,
        lostScore: 0,
        matchNumbers: []
      })
    }
    let matchScoreParameter = ''
    for (const item of matchSchedule) {
      matchScoreParameter += '&kampprogram_id.id=' + Number(item.id).toString()
    }
    matchScoreParameter = matchScoreParameter.slice(1)

    try {
      matchScores = (await Tournaments.TournamentMatchSettlementDataService.getAll('', null, matchScoreParameter)).data
    } catch (err) {
      console.log(err)
    }

    // Constructing the rankings, for each match check that there is some results, then assign scores to the home team and guest team
    for (const match of matchSchedule) {
      const homeTeamId = Number(match.kampprogram_hjemmehold.id)
      const guestTeamId = Number(match.kampprogram_udehold.id)
      let homeScore = 0
      let guestScore = 0
      const scoreIndex = matchScores.findIndex(el => Number(el.kampprogram_id.id) === Number(match.id))

      if (scoreIndex !== -1 && match.kampgodkendt_id !== null && match.kampgodkendt_id.kampgodkendt_godkendt) {
        homeScore = matchScores[scoreIndex].kampafvikling_resultat_hjem === null ? 0 : matchScores[scoreIndex].kampafvikling_resultat_hjem
        guestScore = matchScores[scoreIndex].kampafvikling_resultat_ude === null ? 0 : matchScores[scoreIndex].kampafvikling_resultat_ude
        const homeScoreString = homeScore.toString() + '-' + guestScore.toString()
        const guestScoreString = guestScore.toString() + '-' + homeScore.toString()

        const homeIndex = teamRankingsArray.findIndex(el => el.teamId === Number(homeTeamId))
        const rulesNineToZeroIndex = rules.findIndex(el => el.turneringsregler_resultat === '9-0')

        if (homeIndex !== -1) {
          const wonMatch = homeScore > guestScore
          // Based on the homeScoreString of the homeTeam, find how many points should be awarded
          const rulesIndex = rules.findIndex(el => el.turneringsregler_resultat === homeScoreString)
          console.log('[getRanking()] homeTeamId = ' + Number(homeTeamId).toString())
          console.log('[getRanking()] homeScoreString = ' + homeScoreString)
          console.log('[getRanking()] adding points = ' + (rulesIndex === -1 ? 0 : (isFinishingMatchSeries ? rules[rulesIndex].turneringsregler_point * 2 : rules[rulesIndex].turneringsregler_point)))
          teamRankingsArray[homeIndex].matchNumbers.push(Number(match.id))
          teamRankingsArray[homeIndex].points += (rulesIndex === -1 ? 0 : (isFinishingMatchSeries ? rules[rulesIndex].turneringsregler_point * 2 : rules[rulesIndex].turneringsregler_point))
          teamRankingsArray[homeIndex].matches++
          teamRankingsArray[homeIndex].won += wonMatch ? 1 : 0
          teamRankingsArray[homeIndex].lost += (!wonMatch && (homeScore !== guestScore) ? 1 : 0)
          teamRankingsArray[homeIndex].tied += homeScore === guestScore ? 1 : 0
          teamRankingsArray[homeIndex].wonScore += homeScore
          teamRankingsArray[homeIndex].lostScore += guestScore

          // Handle points reduction for non-appearance situations of the home team in the matches.
          if (match.kampprogram_afb_udb === tournamentMatchScheduleTeamStatusFriendlyType.Udeblivelsehjem) {
            if (seriesString.toLowerCase().includes('hverdag region') && match.raekke_id.raekke_antalspillere === 3) {
              teamRankingsArray[homeIndex].points += -(rulesNineToZeroIndex >= 0 ? rules[rulesNineToZeroIndex].turneringsregler_point : 4)
              console.log('Match Id ' + Number(match.id) + ' POINTS reduction with ' + (rulesNineToZeroIndex >= 0 ? rules[rulesNineToZeroIndex].turneringsregler_point : 4) + ' of home team.')
            } else {
              teamRankingsArray[homeIndex].points += -3
            }
          }

          // If the team has been withdrawn from the turnament season, then set the value of points to zero.
          if (match.kampprogram_hjemmehold.hold_status !== null && match.kampprogram_hjemmehold.hold_status === false) {
            teamRankingsArray[homeIndex].points = 0
          }
        }

        const guestIndex = teamRankingsArray.findIndex(el => el.teamId === Number(guestTeamId))

        if (guestIndex !== -1) {
          const wonMatch = guestScore > homeScore
          // Same for the guestTeam
          const rulesIndex = rules.findIndex(el => el.turneringsregler_resultat === guestScoreString)
          console.log('[getRanking()] guestTeamId = ' + Number(guestTeamId).toString())
          console.log('[getRanking()] guestScoreString = ' + guestScoreString)
          console.log('[getRanking()] adding points = ' + (rulesIndex === -1 ? 0 : (isFinishingMatchSeries ? rules[rulesIndex].turneringsregler_point * 2 : rules[rulesIndex].turneringsregler_point)))
          teamRankingsArray[guestIndex].matchNumbers.push(Number(match.id))
          teamRankingsArray[guestIndex].points += (rulesIndex === -1 ? 0 : (isFinishingMatchSeries ? rules[rulesIndex].turneringsregler_point * 2 : rules[rulesIndex].turneringsregler_point))
          teamRankingsArray[guestIndex].matches++
          teamRankingsArray[guestIndex].won += wonMatch ? 1 : 0
          teamRankingsArray[guestIndex].lost += (!wonMatch && (homeScore !== guestScore) ? 1 : 0)
          teamRankingsArray[guestIndex].tied += guestScore === homeScore ? 1 : 0
          teamRankingsArray[guestIndex].wonScore += guestScore
          teamRankingsArray[guestIndex].lostScore += homeScore

          // Handle points reduction for non-appearance situations of the away team in the matches.
          if (match.kampprogram_afb_udb === tournamentMatchScheduleTeamStatusFriendlyType.Udeblivelseud) {
            if (seriesString.toLowerCase().includes('hverdag region') && match.raekke_id.raekke_antalspillere === 3) {
              teamRankingsArray[homeIndex].points += -(rulesNineToZeroIndex >= 0 ? rules[rulesNineToZeroIndex].turneringsregler_point : 4)
              console.log('Match Id ' + Number(match.id) + ' POINTS reduction with ' + (rulesNineToZeroIndex >= 0 ? rules[rulesNineToZeroIndex].turneringsregler_point : 4) + ' of away team.')
            } else {
              teamRankingsArray[guestIndex].points += -3
            }
          }

          // If the team has been withdrawn from the tournament season, then set the value of points to zero.
          if (match.kampprogram_udehold.hold_status !== null && match.kampprogram_udehold.hold_status === false) {
            teamRankingsArray[guestIndex].points = 0
          }
        }
      }
    }

    // After the ranking array have been constructed, sort it
    teamRankingsArray.sort(function compare (a, b) {
      if (a.points === b.points && (a.matchNumbers.length > 0 || b.matchNumbers.length > 0)) {
        const mutualMatchesNumbers = a.matchNumbers.filter(el => b.matchNumbers.includes(el))
        let teamAWon = 0
        let teamBWon = 0
        let teamAScore = 0
        let teamBScore = 0

        if (mutualMatchesNumbers.length > 0) {
          const teamAId = a.teamId
          // const teamBId = b.teamId
          const mutualMatches = matchScores.filter((el) => {
            return mutualMatchesNumbers.some((f) => {
              return f === Number(el.kampprogram_id.id)
            })
          })
          for (const match of mutualMatches) {
            // Situation where A is the home team
            if (match.kampprogram_id.kampprogram_hjemmehold === teamAId) {
              teamAScore += (match.kampafvikling_resultat_hjem - match.kampafvikling_resultat_ude)
              teamBScore += (match.kampafvikling_resultat_ude - match.kampafvikling_resultat_hjem)

              if (match.kampafvikling_resultat_hjem > match.kampafvikling_resultat_ude) {
                teamAWon++
              }
              if (match.kampafvikling_resultat_hjem < match.kampafvikling_resultat_ude) {
                teamBWon++
              }
            } else {
              teamAScore += (match.kampafvikling_resultat_ude - match.kampafvikling_resultat_hjem)
              teamBScore += (match.kampafvikling_resultat_hjem - match.kampafvikling_resultat_ude)

              if (match.kampafvikling_resultat_ude > match.kampafvikling_resultat_hjem) {
                teamAWon++
              }
              if (match.kampafvikling_resultat_ude < match.kampafvikling_resultat_hjem) {
                teamBWon++
              }
            }
          }
          console.log(teamAId + ' Won: ' + teamAWon)
          console.log(teamBWon + ' Won: ' + teamBWon)
          if (teamAWon === teamBWon) {
            if (teamAScore === teamBScore) {
              const aWonLostRatio = a.wonScore - a.lostScore
              const bWonLostRatio = b.wonScore - b.lostScore

              return bWonLostRatio - aWonLostRatio
            }
            return teamBScore - teamAScore
          }
          return teamBWon - teamAWon
        }
      }
      return b.points - a.points
    })

    return teamRankingsArray
  }
}

export default new TournamentCommonFunctions()
